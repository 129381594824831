<template>
  <div class="product__detail  route__general">
    <h2 class="route__title">İş Makinaları {{ title }}</h2>
    <div class="products__images">
      <Product
        v-for="product in products"
        :key="product.title"
        :product="product"
        :filename="'suRadyatorleri'"
      />
    </div>
  </div>
</template>

<script>
import productData from '../../assets/products/productData.json';
import Product from '../Product.vue';
export default {
  name: 'ProductDetail',
  components: {
    Product
  },
  data() {
    return {
      param: null,
      fullProducts: productData,
      products: []
    };
  },
  computed: {
    title() {
      if (this.param == 'suradyatorleri') {
        return 'Su Radyatörleri';
      } else if (this.param == 'kombiradyatorleri') {
        return 'Kombi Radyatörleri';
      } else if (this.param == 'intercooler') {
        return 'İntercooler';
      } else if (this.param === 'aftercooler') {
        return 'Aftercooler';
      } else if (this.param === 'sogutucu') {
        return 'Soğutucular';
      } else {
        return 'Yağ Soğutucuları';
      }
    }
  },
  created() {
    this.param = this.$route.params.id;

    for (let property in this.fullProducts) {
      if (this.param == property) {
        console.log(property);
        this.products = this.fullProducts[property];
      }
    }

    console.log(this.products);
  }
};
</script>

<style></style>
